<template>
  <list :list="linkedEquipList" :header-list="linkedEquipHeaderList">
    <li slot="list-body" v-for="(linkedEquip, index) in linkedEquipList" :key="index">
      <span>{{ index + 1 }}</span>
      <span>
        <router-link :to="getEqpCodeLink(linkedEquip)">
          {{
            {
              type: isManufacturerComp ? 'M' : undefined,
              mEqpId: linkedEquip['mequipmentId'],
              eqpId: linkedEquip['id'],
            } | formatEQPCode
          }}
        </router-link>
      </span>
      <template v-if="isManufacturerComp">
        <span>{{ linkedEquip.modelName }}</span>
        <span>{{ linkedEquip.factoryCompanyName }}</span>
        <span v-if="linkedEquip.equipmentConfirmsState === 'WAITING'">승인대기</span>
        <span v-else>{{ linkedEquip.equipmentConfmsProcessDT | localDateYYYYMMDD }}</span>
        <span>{{ linkedEquip.isActive ? '활성' : '-' }}</span>
      </template>
      <template v-else>
        <span>{{ linkedEquip.equipmentName }}</span>
      </template>
      <span>{{ linkedEquip.lastCommunicationDT | localDateYYYYMMDDTimeSecond }}</span>
    </li>
    <p slot="no-list-description" class="font-16">센서와 연결된 설비가 없습니다.</p>
  </list>
</template>

<script>
import { mapGetters } from 'vuex';
import List from '@/component/list/List';
import ListHeaderData from './ListHeaderData.json';

export default {
  name: 'LinkedSensorEquipmentList',
  props: ['linkedEquipList'],
  data() {
    return {
      linkedEquipHeaderList: ListHeaderData,
    };
  },
  computed: {
    ...mapGetters('users', ['isFactoryComp', 'isManufacturerComp']),
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    getEqpCodeLink(linkedEquip) {
      if (this.isFactoryComp && !linkedEquip['isSelfEquipment']) {
        return `/factory-equipments/${linkedEquip.id}`;
      } else {
        return `/equipments/${linkedEquip.id}`;
      }
    },
  },
  components: { List },
};
</script>

<style scoped lang="scss">
@import 'LinkedSensorEquipmentList';
</style>
