var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-white has-no-scroll" },
    [
      _c("main-section-header", {
        attrs: { title: "센서 상세정보", "is-sticky": true },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("subtitle", { attrs: { subtitle: "센서 정보" } }),
          _c(
            "ul",
            {
              staticClass: "detail-list",
              class: { "show-textarea": _vm.isShowTextArea },
            },
            _vm._l(_vm.sensorInfo, function (info, index) {
              return _c(
                "detail-list-row",
                {
                  key: index,
                  attrs: {
                    info: info,
                    inputKey: info.key,
                    "input-value": _vm.sensorData[info.key],
                    maxlength: info.maxlength,
                    placeholder: info.placeholder,
                  },
                  on: {
                    saveInputValue: _vm.onSaveEditValue,
                    showTextarea: _vm.onShowTextArea,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "label",
                      class: { "label-required": info.isRequired },
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [_vm._v(" " + _vm._s(info.label) + " ")]
                  ),
                  info.key === "id"
                    ? _c(
                        "span",
                        {
                          staticClass: "contents",
                          attrs: { slot: "contents" },
                          slot: "contents",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatSensorCode")({
                                  type: _vm.currentCompany,
                                  sensorId: _vm.sensorData.id,
                                })
                              ) +
                              " "
                          ),
                        ]
                      )
                    : info.key === "createDT"
                    ? _c(
                        "span",
                        {
                          staticClass: "contents",
                          attrs: { slot: "contents" },
                          slot: "contents",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localDateYYYYMMDD")(
                                  _vm.sensorData[info.key]
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : info.key === "lastCommunicationDT"
                    ? _c(
                        "span",
                        {
                          staticClass: "contents",
                          attrs: { slot: "contents" },
                          slot: "contents",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("localDateYYYYMMDDTimeSecond")(
                                  _vm.sensorData[info.key]
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "contents",
                          attrs: { slot: "contents" },
                          slot: "contents",
                        },
                        [_vm._v(_vm._s(_vm.sensorData[info.key] || "-"))]
                      ),
                ]
              )
            }),
            1
          ),
          _c("subtitle", {
            staticClass: "mb-20",
            attrs: { subtitle: "연결된 설비 정보" },
          }),
          _c(
            "div",
            { staticClass: "mb-30" },
            [
              _c("linked-sensor-equipment-list", {
                attrs: { "linked-equip-list": _vm.sensorData.equipments },
              }),
            ],
            1
          ),
          _c(
            "list-bottom-button-box",
            [
              _c(
                "button-basic",
                {
                  attrs: { slot: "button", color: "gray-border-1", width: 120 },
                  on: { click: _vm.onShowDeleteSensorPopup },
                  slot: "button",
                },
                [_vm._v(" 센서 삭제 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ConfirmPopup",
          "contents-key": _vm.popupContentsKey,
        },
        on: { close: _vm.onCloseConfirmPopup, confirm: _vm.onConfirmInPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }