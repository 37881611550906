var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "list",
    {
      attrs: {
        list: _vm.linkedEquipList,
        "header-list": _vm.linkedEquipHeaderList,
      },
    },
    [
      _vm._l(_vm.linkedEquipList, function (linkedEquip, index) {
        return _c(
          "li",
          { key: index, attrs: { slot: "list-body" }, slot: "list-body" },
          [
            _c("span", [_vm._v(_vm._s(index + 1))]),
            _c(
              "span",
              [
                _c(
                  "router-link",
                  { attrs: { to: _vm.getEqpCodeLink(linkedEquip) } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatEQPCode")({
                            type: _vm.isManufacturerComp ? "M" : undefined,
                            mEqpId: linkedEquip["mequipmentId"],
                            eqpId: linkedEquip["id"],
                          })
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.isManufacturerComp
              ? [
                  _c("span", [_vm._v(_vm._s(linkedEquip.modelName))]),
                  _c("span", [_vm._v(_vm._s(linkedEquip.factoryCompanyName))]),
                  linkedEquip.equipmentConfirmsState === "WAITING"
                    ? _c("span", [_vm._v("승인대기")])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("localDateYYYYMMDD")(
                              linkedEquip.equipmentConfmsProcessDT
                            )
                          )
                        ),
                      ]),
                  _c("span", [
                    _vm._v(_vm._s(linkedEquip.isActive ? "활성" : "-")),
                  ]),
                ]
              : [_c("span", [_vm._v(_vm._s(linkedEquip.equipmentName))])],
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("localDateYYYYMMDDTimeSecond")(
                    linkedEquip.lastCommunicationDT
                  )
                )
              ),
            ]),
          ],
          2
        )
      }),
      _c(
        "p",
        {
          staticClass: "font-16",
          attrs: { slot: "no-list-description" },
          slot: "no-list-description",
        },
        [_vm._v("센서와 연결된 설비가 없습니다.")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }