<template>
  <section class="main-section bg-white has-no-scroll">
    <main-section-header title="센서 상세정보" :is-sticky="true" />
    <div class="container">
      <subtitle subtitle="센서 정보" />
      <ul class="detail-list" :class="{ 'show-textarea': isShowTextArea }">
        <detail-list-row
          v-for="(info, index) in sensorInfo"
          :key="index"
          :info="info"
          :inputKey="info.key"
          :input-value="sensorData[info.key]"
          :maxlength="info.maxlength"
          :placeholder="info.placeholder"
          @saveInputValue="onSaveEditValue"
          @showTextarea="onShowTextArea"
        >
          <span slot="label" class="label" :class="{ 'label-required': info.isRequired }">
            {{ info.label }}
          </span>
          <span slot="contents" class="contents" v-if="info.key === 'id'">
            {{ { type: currentCompany, sensorId: sensorData.id } | formatSensorCode }}
          </span>
          <span slot="contents" class="contents" v-else-if="info.key === 'createDT'">
            {{ sensorData[info.key] | localDateYYYYMMDD }}
          </span>
          <span slot="contents" class="contents" v-else-if="info.key === 'lastCommunicationDT'">
            {{ sensorData[info.key] | localDateYYYYMMDDTimeSecond }}
          </span>
          <span slot="contents" class="contents" v-else>{{ sensorData[info.key] || '-' }}</span>
        </detail-list-row>
      </ul>
      <subtitle subtitle="연결된 설비 정보" class="mb-20" />
      <div class="mb-30">
        <linked-sensor-equipment-list :linked-equip-list="sensorData.equipments" />
      </div>
      <list-bottom-button-box>
        <button-basic slot="button" color="gray-border-1" :width="120" @click="onShowDeleteSensorPopup">
          센서 삭제
        </button-basic>
      </list-bottom-button-box>
    </div>
    <confirm-popup
      :is-show="showedPopupName === 'ConfirmPopup'"
      :contents-key="popupContentsKey"
      @close="onCloseConfirmPopup"
      @confirm="onConfirmInPopup"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Subtitle from '@/component/subtitle/Subtitle';
import ListBottomButtonBox from '@/component/list/ListBottomButtonBox';
import DetailListRow from '@/component/detailListRow/DetailListRow';
import SensorDetailData from './SensorDetailData.json';
import LinkedSensorEquipmentList from '@/view/sensors/sensorDetail/linkedSensorEquipmentList/LinkedSensorEquipmentList';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';

export default {
  name: 'SensorDetail',
  props: [],
  data() {
    return {
      sensor: null,
      sensorInfo: SensorDetailData,
      sensorId: this.$route.params.sId,
      sensorData: {
        modelName: '',
        serialNumber: null,
        description: '',
      },
      showedPopupName: '',
      popupContentsKey: '',
      isShowTextArea: false,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
  },
  created() {
    if (this.sensorId) {
      this.sensorData = this.getSensorData(this.sensorId);
    }
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('manufacturerSensors', [
      'GET_MANUFACTURER_SENSOR',
      'PUT_MANUFACTURER_SENSOR',
      'DELETE_MANUFACTURER_SENSOR',
    ]),
    ...mapActions('factorySensors', ['GET_FACTORY_SENSOR', 'PUT_FACTORY_SENSOR', 'DELETE_FACTORY_SENSOR']),
    async getSensorData(sid) {
      try {
        switch (this.currentCompany) {
          case 'M':
            this.sensorData = await this.GET_MANUFACTURER_SENSOR(sid);
            break;
          case 'F':
            this.sensorData = await this.GET_FACTORY_SENSOR(sid);
            break;
        }
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    async onSaveEditValue(inputKey, changedValue) {
      this.sensorData[inputKey] = changedValue;
      switch (this.currentCompany) {
        case 'M':
          await this.PUT_MANUFACTURER_SENSOR([this.sensorId, this.sensorData]);
          break;
        case 'F':
          await this.PUT_FACTORY_SENSOR([this.sensorId, this.sensorData]);
          break;
      }
    },
    async onDeleteSensor() {
      switch (this.currentCompany) {
        case 'M':
          await this.DELETE_MANUFACTURER_SENSOR(this.sensorId);
          break;
        case 'F':
          await this.DELETE_FACTORY_SENSOR(this.sensorId);
          break;
      }
      this.togglePopup('ConfirmPopup', 'DELETE_SENSOR_SUCCESS');
    },
    onShowDeleteSensorPopup() {
      this.popupContentsKey = this.sensorData.equipments.length > 0 ? 'IMPOSSIBLE_DELETE_SENSOR' : 'DELETE_SENSOR';
      this.togglePopup('ConfirmPopup', this.popupContentsKey);
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_SENSOR':
          this.onDeleteSensor();
          break;
        default:
          break;
      }
    },
    onCloseConfirmPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_SENSOR_SUCCESS':
          this.togglePopup();
          this.$router.push(`/sensors`).catch((err) => err);
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onShowTextArea(isEdit) {
      this.isShowTextArea = isEdit;
    },
  },
  components: {
    MainSectionHeader,
    Subtitle,
    DetailListRow,
    ListBottomButtonBox,
    LinkedSensorEquipmentList,
    ConfirmPopup,
  },
};
</script>

<style scoped lang="scss">
@import 'SensorDetail';
</style>
